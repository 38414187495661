.home {
    background-color: rgb(229,221,208);
    background-image: url(../../assets/home.png);
    background-size: cover;
    min-height: 100vh;
  
    & nav {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      color: #0c0a0a;
      & > .logo {
        color: #0c1340;
      }
    }
  }

  .header {
    background-image: url(../../assets/main-img.jpg);
    background-size: cover;
  }
  
  .home-links {
    display: flex;
    justify-content: center;
    align-items: center;
    & li a {
      color: #0a0909;
    }
    & > * {
      margin-left: 1rem;
      color: #fbf8f8;
    }
   
  }
  
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hero-text {
    width: 100%;
    & > * {
      color: #0d032f;
      margin-bottom: 2rem;
    }
    & > h2 span {
      display: block;
    }
    & .hero-buttons a {
      color: #238107;
    }
  }
  .hero-image {
    width: 40%;
  }
  @media screen and (max-width: 600px) {
    .hero {
      flex-direction: column;
    }
  
    .hero-text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin: auto;
      text-align: center;
    }
    .hero-image {
      width: 100%;
      &img {
        width: 70%;
      }
    }
  }

  .image-section {
    margin-top: 50px;
  }
  
  .image-row {
    display: flex;
    justify-content: space-between;
  }
  
  .image-container {
    position: relative;
    width: 30%;
    overflow: hidden;
    border-radius: 30px;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .image-overlay a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
  }
  
  .image-container:hover img {
    transform: scale(1.05);
  }
  
  .image-container:hover .image-overlay {
    opacity: 1;
  }