.info-box {
    width: 100%;
    height: 7rem;
    max-width: 22rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    flex-wrap: wrap;
    color: #fff;
    transform: translateY(0);
    transition: all 0.3s;
  
    // background-color: green;
  
    &:hover {
      cursor: pointer;
      // border: 3px solid #fff;
      transform: translateY(-5px);
    }
  
    .info-icon {
      padding: 0 2rem;
      color: #fff;
    }
    .info-text > * {
      color: #fff;
    }
  
    // .card {
    //   border: 1px solid #ccc;
    //   border-bottom: 3px solid var(--light-blue);
    //   padding: 5px;
    //   background-color: #f5f6fa;
    // }
  }
  
  @media screen and (max-width: 600px) {
    .info-box {
      max-width: 100%;
    }
  }